import { mapActions } from 'vuex';

export default {
  name: 'sendSms',
  data() {
    return {
      number: null,
      regex: /^(\d{9})$/,
      error: false,
      success: false,
    };
  },
  i18n: {
    messages: {
      ru: {
        'Отримати посилання': 'Получить ссылку',
        або: 'или',
        Надіслано: 'Отправлено',
        'Номер був вказаний невірно': 'Номер был указан неверно',
      },
      uk: {
        'Отримати посилання': 'Отримати посилання',
        або: 'або',
        Надіслано: 'Надіслано',
        'Номер був вказаний невірно': 'Номер був вказаний невірно',
      },
    },
  },
  props: ['segment'],
  computed: {
    updateSegment() {
      return this.segment.match(/b2c|smb/i) ? 'b2c' : 'c2c';
    },
  },
  methods: {
    ...mapActions({
      sendPhone: 'AppVideoCalls/sendPhone',
    }),
    send() {
      if (this.regex.test(this.number)) {
        this.sendPhone({
          phone: this.number,
          segment: this.updateSegment,
        }).then(() => {
          this.success = true;
          this.number = '';
          this.sendLogs(2);
        });
      } else {
        this.error = true;
        this.sendLogs(3);
      }
    },
    checkNumber() {
      if (this.regex.test(this.number)) {
        this.error = false;
      }
    },
    sendLogs(clickAction) {
      this.$emit('slonikLogs', clickAction);
    },
  },
};
