import { mapGetters } from 'vuex';

export default {
  name: 'appButtons',
  props: ['mobileHide', 'landId', 'segment', 'setStickyClass'],
  data() {
    return {
      os: '',
      huaweiRegex: /huawei|(nexus\s6p|vog-l29|ane-lx1|INE-LX1|eml-l29|ele-l29)|android.+(bah2?-a?[lw]\d{2})|(MediaPad|MediaPad 7 Youth|IDEOS S7|S7-201c|S7-202u|S7-101|S7-103|S7-104|S7-105|S7-106|S7-201|S7-Slim|M2-A01L|BAH-L09|BAH-W09|AGS-L09|CMR-AL19)/i,
      iosLink: 'https://apps.apple.com/ua/app/auto-ria-cars-for-sale/id1083363297?l=uk?l=uk',
      androidLink: 'https://play.google.com/store/apps/details?id=com.ria.auto&hl=uk',
      huaweiLink: 'https://appgallery.huawei.com/app/C101464449',
    };
  },
  i18n: {
    messages: {
      ru: {
        'Установите или обновите уже сейчас:': 'Установите или обновите уже сейчас:',
        'завантажити з': 'скачать с',
        досліджуй: 'исследуй',
      },
      uk: {
        'Установите или обновите уже сейчас:': 'Встановіть чи оновіть вже зараз:',
        'завантажити з': 'завантажити з',
        досліджуй: 'досліджуй',
      },
    },
  },
  mounted() {
    this.getMobileOs();
  },
  methods: {
    getMobileOs() {
      if (navigator && navigator.userAgent.match(this.huaweiRegex)) {
        this.os = 'huawei';
      } else if (navigator && navigator.userAgent.match(/Android/i)) {
        this.os = 'android';
      } else if (navigator && navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i)) {
        this.os = 'ios';
      }
    },
    sendLogs(clickAction) {
      this.$emit('slonikLogs', clickAction);
    },
  },
};
